const GUEST = "Guest";
const USER = "User";
const REALM_ADMINISTRATOR = "Realm Administrator";
const INCENTIVE_ADMINISTRATOR = "Incentive Administrator";
const INCENTIVE_ANALYST = "Incentive Analyst";
const PENDING_ANALYST = "Pending Analyst";
const ADMIN_GROUP = [REALM_ADMINISTRATOR, INCENTIVE_ADMINISTRATOR];

module.exports = {
  GUEST,
  USER,
  REALM_ADMINISTRATOR,
  INCENTIVE_ADMINISTRATOR,
  INCENTIVE_ANALYST,
  PENDING_ANALYST,
  ADMIN_GROUP,
};
