/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PageRedirectHandlerQueryVariables = {};
export type PageRedirectHandlerQueryResponse = {
    readonly session: {
        readonly userGroups: ReadonlyArray<string | null> | null;
        readonly ciipUserBySub: {
            readonly __typename: string;
        } | null;
    } | null;
};
export type PageRedirectHandlerQuery = {
    readonly response: PageRedirectHandlerQueryResponse;
    readonly variables: PageRedirectHandlerQueryVariables;
};



/*
query PageRedirectHandlerQuery {
  session {
    userGroups
    ciipUserBySub {
      __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userGroups",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PageRedirectHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JwtToken",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CiipUser",
            "kind": "LinkedField",
            "name": "ciipUserBySub",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PageRedirectHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JwtToken",
        "kind": "LinkedField",
        "name": "session",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CiipUser",
            "kind": "LinkedField",
            "name": "ciipUserBySub",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca1486da3584dafa306966c105390089",
    "id": null,
    "metadata": {},
    "name": "PageRedirectHandlerQuery",
    "operationKind": "query",
    "text": "query PageRedirectHandlerQuery {\n  session {\n    userGroups\n    ciipUserBySub {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c53bf2b49e0d72f65f7b05348d66a86a';
export default node;
