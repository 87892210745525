/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserProfileDropdown_user = {
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly emailAddress: string | null;
    readonly " $refType": "UserProfileDropdown_user";
};
export type UserProfileDropdown_user$data = UserProfileDropdown_user;
export type UserProfileDropdown_user$key = {
    readonly " $data"?: UserProfileDropdown_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserProfileDropdown_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileDropdown_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "CiipUser",
  "abstractKey": null
};
(node as any).hash = '012bdd8fcb441ec80f847517508c4065';
export default node;
