/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type defaultLayout_session = {
    readonly ciipUserBySub: {
        readonly " $fragmentRefs": FragmentRefs<"UserProfileDropdown_user">;
    } | null;
    readonly userGroups: ReadonlyArray<string | null> | null;
    readonly " $refType": "defaultLayout_session";
};
export type defaultLayout_session$data = defaultLayout_session;
export type defaultLayout_session$key = {
    readonly " $data"?: defaultLayout_session$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"defaultLayout_session">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "defaultLayout_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CiipUser",
      "kind": "LinkedField",
      "name": "ciipUserBySub",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileDropdown_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userGroups",
      "storageKey": null
    }
  ],
  "type": "JwtToken",
  "abstractKey": null
};
(node as any).hash = '51af6317561dbdf4ae915dd04a640651';
export default node;
